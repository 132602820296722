import {
  IonAlert,
  IonBackButton,
  IonBadge,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Auth from "../components/Auth";
import Alib from "../components/ALib";
import { useHistory } from "react-router-dom";
import { calendar, saveOutline } from "ionicons/icons";

import ALib from "../components/ALib";
const ViewDetails: React.FC = () => {
  const history = useHistory();
  const [showMessage] = useIonAlert();

  const [showAlert, setshowAlert] = useState(false);

  //initialize data
  const loaddata = {
    PK_transaction: 0,
    lastname: "",
    firstname: "",
    middlename: "",
    initals: "",
    name_show: "",
    source: "",
    querytype: "",
    concern: "",
    contact: "",
    tel: "",
    email: "",
    notes: "",
    isdone: 0,
    datatransac: Alib.today(),
    datetransacclose: Alib.today(),
    username_initial: "",
    username_close: "",
    isconverted: 0,
  };

  const [DataSource, setDataSource] = useState(loaddata);

  function fetchall() {
    fetch(Auth.globalwebhost + "/CSUapp/viewdetails.php", {
      method: "POST",
      body: JSON.stringify({
        PK_transaction: Auth.PK_transaction,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        ALib.copyData(responseJson[0], loaddata, setDataSource);
      });
  }

  function updatestatus() {
    fetch(Auth.globalwebhost + "/CSUapp/updatestatus.php", {
      method: "POST",
      body: JSON.stringify({
        PK_transaction: Auth.PK_transaction,
        isdone: DataSource.isdone,
        FK_user_close: Auth.PK_user,
        username_close: Auth.username,
        datetransacclose: Alib.todaydatetime2(DataSource.datatransac),
        isconverted: DataSource.isconverted,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.result);
        if (responseJson.result === 1) {
          showMessage({
            header: "Success",
            message: "Successfully Status Closed.",
            buttons: ["OK"],
          });
          history.replace("/mdashboard");
        } else {
          showMessage({
            header: "Error Message",
            message: "Invalid Update.",
            buttons: ["OK"],
          });
        }
      });
  }

  useEffect(() => {
    fetchall();
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonMenuButton />
            <IonBackButton defaultHref="/mdashboard" />
            <IonTitle>View Details</IonTitle>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonRow>
                    <IonCol>
                      <IonCardTitle>Customer Details</IonCardTitle>
                    </IonCol>
                  </IonRow>
                </IonCardHeader>

                <IonItem>
                  <IonRow>
                    <IonText>Name: {DataSource.name_show}</IonText>
                  </IonRow>
                </IonItem>
                <IonItem>
                  <IonRow>
                    <IonText>Source:{DataSource.source} </IonText>
                  </IonRow>
                </IonItem>
                <IonItem>
                  <IonRow>
                    <IonText>Query:{DataSource.querytype} </IonText>
                  </IonRow>
                </IonItem>
                <IonItem>
                  <IonRow>
                    <IonText>Concern:{DataSource.concern} </IonText>
                  </IonRow>
                </IonItem>
              </IonCard>
            </IonCol>

            <IonCard>
              <IonCardHeader>
                <IonRow>
                  <IonCardTitle>Users</IonCardTitle>
                </IonRow>
              </IonCardHeader>

              <IonItem>
                <IonCol size="6">
                  <IonText>
                    Inital Process: {DataSource.username_initial}
                  </IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText>
                    Date Process: {DataSource.datatransac}
                  </IonText>
                </IonCol>
              </IonItem>

              <IonItem>
                <IonCol size="6">
                  <IonText>Closed:{DataSource.username_close}</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonItem>
                    Date Closed:{" "}
                    {DataSource.datetransacclose}
                  </IonItem>
                </IonCol>
              </IonItem>
            </IonCard>
          </IonRow>

          <IonCard>
            <IonListHeader lines="full">
              <IonCol>
                <IonText>Change Current Status </IonText>
              </IonCol>

              <IonCol>
                <IonLabel>Current Status : </IonLabel>
                <IonBadge color="medium">
                  {Auth.typestatus(DataSource.isdone)}
                </IonBadge>
              </IonCol>
            </IonListHeader>

            <IonItem>
              <IonLabel>Status</IonLabel>
              <IonSelect
                value={DataSource.isdone}
                onIonChange={(e) =>
                  setDataSource((data: any) => ({
                    ...data,
                    isdone: e.detail.value,
                  }))
                }
              >
                <IonSelectOption value="0">Pending</IonSelectOption>
                <IonSelectOption value="1">Closed</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonInput
                id="date-input-2"
                value={Alib.formatDate(DataSource.datatransac)}
              />
              <IonButton fill="clear" id="open-date-input-2">
                <IonIcon icon={calendar} />
              </IonButton>
              <IonPopover trigger="open-date-input-2" showBackdrop={false}>
                <IonDatetime
                  presentation="date"
                  onIonChange={(e) =>
                    setDataSource((data: any) => ({
                      ...data,
                      datatransac: e.detail.value!,
                    }))
                  }
                />
              </IonPopover>
            </IonItem>

            <IonRadioGroup
              value={DataSource.isconverted}
              onIonChange={(e) =>
                setDataSource((data: any) => ({
                  ...data,
                  isconverted: e.detail.value!,
                }))
              }
            >
              <IonRow>
                <IonText className="ion-margin">Converted</IonText>
              </IonRow>
              <IonRow>
                <IonItem lines="none">
                  <IonLabel>YES</IonLabel>
                  <IonRadio slot="start" value={"1"} color="dark" />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>No</IonLabel>
                  <IonRadio slot="start" value={"0"} color="dark" />
                </IonItem>

                <IonItem lines="none">
                  <IonLabel>N/A</IonLabel>
                  <IonRadio slot="start" value={"2"} color="dark" />
                </IonItem>
              </IonRow>
            </IonRadioGroup>

            <IonButton
              // hidden={Boolean(initialize.statusnow === "1" ? false : true)}
              shape="round"
              type="submit"
              color="success"
              onClick={() => {
                setshowAlert(true);
              }}
            >
              Save
              <IonIcon slot="start" icon={saveOutline} />
            </IonButton>
          </IonCard>
        </IonCard>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setshowAlert(false)}
          header={"Confirmation : CSU APP"}
          message={"Are you want to update status"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
              handler: (data: any) => {
                console.log(data);
              },
            },
            {
              text: "Save",
              id: "confirm-button",
              handler: () => {
                updatestatus();
              },
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ViewDetails;
