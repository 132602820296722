import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import MUIDataTable from "mui-datatables";
import Auth from "../components/Auth";
import Alib from "../components/ALib";

import {
  addCircleOutline,
  checkmarkOutline,
  closeCircleOutline,
  logOutOutline,
  medkitOutline,
} from "ionicons/icons";
import { useHistory, useLocation } from "react-router";

const MainDashboard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  //initalize data
  const [initialize, setinitialize] = useState({
    datefrom: Alib.today(),
    dateto: Alib.today(),
    DataSource: [],
    showAlert: false,
    ShowModal: false,
    ShowInfo: false,
    typedrop: "0",
  });
  //incoming data
  const [incoming, setincoming] = useState<any>({
    Source: [],
    QueryType: [],
    Concern: [],
  });

  //outgoing data
  const [Data, setData] = useState({
    lastname: "",
    firstname: "",
    middlename: "",
    initials: "",
    sourcedata: "",
    querytypedata: "",
    concerndata: "",
    contact: "",
    tel: "",
    email: "",
    notes: "",
    isdone: 0,
    FK_user_initial: Auth.PK_user,
    username_initial: Auth.username,
    isconverted: 0,
  });

  const handleChange = (field: any) => {
    return (e: { detail: { value: any } }) =>
      setData((name) => ({ ...name, [field]: e.detail.value! }));
  };

  function fetchall() {
    fetch(Auth.globalwebhost + "/CSUapp/listtransactions.php", {
      method: "POST",
      body: JSON.stringify({
        datefrom: initialize.datefrom,
        dateto: initialize.dateto,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        setinitialize((name) => ({ ...name, DataSource: responseJson }));
      });

    fetch(Auth.globalwebhost + "/CSUapp/listofcsuclass.php", {
      method: "POST",
      body: JSON.stringify({
        PK_class: initialize.typedrop,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (initialize.typedrop === "1") {
          setincoming((name: any) => ({ ...name, Source: responseJson }));
        } else if (initialize.typedrop === "2") {
          setincoming((name: any) => ({ ...name, QueryType: responseJson }));
        } else if (initialize.typedrop === "3") {
          setincoming((name: any) => ({ ...name, Concern: responseJson }));
        }
      });
  }

  useEffect(() => {
    fetchall();
  }, [initialize.typedrop, initialize.datefrom, initialize.dateto, location]);

  function InsertInformation() {
    if (
      Data.lastname &&
      Data.firstname &&
      Data.sourcedata &&
      Data.querytypedata &&
      Data.concerndata !== null
    ) {
      fetch(Auth.globalwebhost + "/CSUapp/incsutransac.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.result == 1) {
            setinitialize((data) => ({ ...data, ShowModal: false }));
          } else {
            alert("Invalid Response");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please Fill the Necessary Fields");
    }
  }

  const columns12 = [
    {
      name: "PK_transaction",
      label: "",
      options: {
        display: false,
        sort: false,
        viewColumns: false,
        download: false,
        filter: false,
      },
    },
    {
      name: "dateset",
      label: "Date ",
      options: {
        sort: false,
        viewColumns: false,
        expandableRows: true,
      },
    },
    {
      name: "timeset",
      label: "Time",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: "entrynumber",
      label: "ID",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "name_show",
      label: "Name",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "source",
      label: "Source",
    },
    {
      name: "querytype",
      label: "Query",
    },
    {
      name: "concern",
      label: "Concern",
    },

    {
      name: "contact",
      label: "Contact",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "tel",
      label: "Tel #.",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "email",
      label: "Email",
      options: {
        sort: true,
        filter: false,
      },
    },

    {
      name: "username_initial",
      label: "User Process",
      options: {
        sort: true,
      },
    },
    {
      name: "username_close",
      label: "User Close",
      options: {
        sort: true,
      },
    },
    {
      name: "isdone",
      label: "Status",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          if (value === "0") {
            return (
              <IonText color="danger">
                {" "}
                <b>Pending</b>
              </IonText>
            );
          } else if (value === "1") {
            return (
              <IonText color="success">
                {" "}
                <b>Closed</b>
              </IonText>
            );
          }
        },
      },
    },
    {
      name: "isconverted",
      label: "Converted",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          if (value === "0") {
            return (
              <IonIcon
                color="danger"
                slot="icon-only"
                icon={closeCircleOutline}
              />
            );
          } else if (value === "1") {
            return (
              <IonIcon
                color="success"
                slot="icon-only"
                icon={checkmarkOutline}
              />
            );
          }
        },
      },
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        //filter: true,
        sort: true,
        filter: false,
      },
    },
  ];

  const NavCallback = () => {
    history.push({
      pathname: `/viewdetails`,
    });
  };

  const options: any = {
    onRowClick: (
      rowData: string[]
      // rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      Auth.navclient(NavCallback, rowData[0]);
    },
    download: true,

    expandableRowsHeader: false,
    filter: true,
    print: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    responsive: "standard",
    Animation: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-boarder">
        <IonToolbar className="tabcolor">
          <IonRow>
            <IonMenuButton />
            <IonTitle>Main Dashboard</IonTitle>
            <IonButton
              color="success"
              onClick={() => {
                Auth.logout(() => {
                  history.replace("/login");
                });
              }}
            >
              <IonIcon slot="icon-only" icon={logOutOutline} />
            </IonButton>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonCard>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonRow>
                    <IonCol>
                      <IonCardTitle>Customer Service</IonCardTitle>
                    </IonCol>

                    <IonItem button={true} id="datefrom">
                      <IonLabel>Date From:</IonLabel>
                      <IonText slot="end">{initialize.datefrom}</IonText>
                      <IonPopover trigger="datefrom" showBackdrop={false}>
                        <IonDatetime
                          presentation="date"
                          onIonChange={(e) =>
                            setinitialize((data) => ({
                              ...data,
                              datefrom: Alib.formatDate(e.detail.value!),
                            }))
                          }
                        />
                      </IonPopover>
                    </IonItem>

                    <IonItem button={true} id="dateto">
                      <IonLabel>Date To</IonLabel>
                      <IonText slot="end">{initialize.dateto}</IonText>
                      <IonPopover trigger="dateto" showBackdrop={false}>
                        <IonDatetime
                          presentation="date"
                          onIonChange={(e) =>
                            setinitialize((data) => ({
                              ...data,
                              dateto: Alib.formatDate(e.detail.value!),
                            }))
                          }
                        />
                      </IonPopover>
                    </IonItem>

                    <IonButton
                      shape="round"
                      type="submit"
                      color="medium"
                      onClick={() =>
                        setinitialize((data) => ({ ...data, ShowModal: true }))
                      }
                    >
                      Add Entry
                      <IonIcon slot="start" icon={medkitOutline} />
                    </IonButton>
                    <IonButton
                      shape="round"
                      type="submit"
                      color="success"
                      onClick={() => fetchall()}
                    >
                      Refresh
                      <IonIcon slot="start" icon={medkitOutline} />
                    </IonButton>
                    <IonButton
                      hidden={true}
                      shape="round"
                      type="submit"
                      color="success"
                      onClick={() =>
                        setinitialize((data) => ({ ...data, ShowModal: true }))
                      }
                    >
                      Full Details
                      <IonIcon slot="start" icon={medkitOutline} />
                    </IonButton>
                  </IonRow>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          </IonRow>

          <MUIDataTable
            title={"List of Inquiries"}
            data={initialize.DataSource}
            columns={columns12}
            options={options}
          />
        </IonCard>
      </IonContent>

      <IonModal isOpen={initialize.ShowModal} backdropDismiss={false}>
        <IonHeader>
          <IonToolbar className="tabcolor">
            <IonTitle>Add Entry</IonTitle>
            <IonRow slot="end"></IonRow>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonAlert
            isOpen={initialize.showAlert}
            onDidDismiss={() =>
              setinitialize((data) => ({ ...data, showAlert: false }))
            }
            header={"Confirmation : CSU APP"}
            message={"Are you sure you want to continue"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                id: "cancel-button",
                handler: (data: any) => {},
              },
              {
                text: "Save",
                id: "confirm-button",
                handler: () => {
                  InsertInformation();
                },
              },
            ]}
          />
          <form>
            <IonGrid className="ion-text-sm-justify ion-padding-horizontal">
              <IonList lines="full" className="ion-no-margin">
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonText> L. Name:</IonText>
                      <IonText color="danger">*</IonText>
                      <IonInput
                        value={Data.lastname}
                        onIonChange={handleChange("lastname")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonText> F. Name: </IonText>
                      <IonText color="danger">*</IonText>
                      <IonInput
                        value={Data.firstname}
                        onIonChange={handleChange("firstname")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonText> M. Name:</IonText>
                      <IonInput
                        value={Data.middlename}
                        onIonChange={handleChange("middlename")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonText> M.I: </IonText>
                      <IonInput
                        value={Data.initials}
                        onIonChange={handleChange("initials")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonItem
                  onClick={() =>
                    setinitialize((data) => ({ ...data, typedrop: "1" }))
                  }
                >
                  <IonLabel>
                    Select Source: <IonText color="danger">*</IonText>{" "}
                  </IonLabel>

                  <IonSelect
                    value={Data.sourcedata}
                    onIonChange={handleChange("sourcedata")}
                  >
                    {incoming.Source?.map(
                      (
                        m: {
                          text:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined;
                        },
                        index: Key | null | undefined
                      ) => (
                        <IonSelectOption key={index} value={m.text}>
                          {m.text}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                </IonItem>

                <IonItem
                  onClick={() =>
                    setinitialize((data) => ({ ...data, typedrop: "2" }))
                  }
                >
                  <IonLabel>
                    Select Query Type: <IonText color="danger">*</IonText>{" "}
                  </IonLabel>

                  <IonSelect
                    value={Data.querytypedata}
                    onIonChange={handleChange("querytypedata")}
                  >
                    {incoming.QueryType?.map(
                      (
                        m: {
                          text:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined;
                        },
                        index: Key | null | undefined
                      ) => (
                        <IonSelectOption key={index} value={m.text}>
                          {m.text}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                </IonItem>

                <IonItem
                  onClick={() =>
                    setinitialize((data) => ({ ...data, typedrop: "3" }))
                  }
                >
                  <IonLabel>
                    Select Concern: <IonText color="danger">*</IonText>{" "}
                  </IonLabel>

                  <IonSelect
                    value={Data.concerndata}
                    onIonChange={handleChange("concerndata")}
                  >
                    {incoming.Concern?.map(
                      (
                        m: {
                          text:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined;
                        },
                        index: Key | null | undefined
                      ) => (
                        <IonSelectOption key={index} value={m.text}>
                          {m.text}
                        </IonSelectOption>
                      )
                    )}
                  </IonSelect>
                </IonItem>

                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel> Contact #:</IonLabel>
                      <IonInput
                        value={Data.contact}
                        onIonChange={handleChange("contact")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                  <IonCol>
                    <IonItem>
                      <IonLabel> Tel No:</IonLabel>
                      <IonInput
                        value={Data.tel}
                        onIonChange={handleChange("tel")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel> Email:</IonLabel>
                      <IonInput
                        value={Data.email}
                        onIonChange={handleChange("email")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonLabel> Notes:</IonLabel>
                      <IonInput
                        value={Data.notes}
                        onIonChange={handleChange("notes")}
                      ></IonInput>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    <IonButton
                      shape="round"
                      color="success"
                      onClick={() =>
                        setinitialize((data) => ({ ...data, showAlert: true }))
                      }
                    >
                      Save
                      <IonIcon slot="start" icon={addCircleOutline} />
                    </IonButton>
                  </IonCol>
                  <IonCol className="ion-text-center">
                    <IonButton shape="round" type="reset" color="success">
                      Clear
                      <IonIcon slot="start" icon={closeCircleOutline} />
                    </IonButton>
                  </IonCol>
                  <IonCol className="ion-text-center">
                    <IonButton
                      shape="round"
                      color="success"
                      onClick={() =>
                        setinitialize((data) => ({ ...data, ShowModal: false }))
                      }
                    >
                      Close
                      <IonIcon slot="start" icon={closeCircleOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonList>
            </IonGrid>
          </form>
        </IonContent>
      </IonModal>
      <IonModal isOpen={initialize.ShowInfo} backdropDismiss={false}>
        <IonContent>Modal Content</IonContent>
      </IonModal>
    </IonPage>
  );
};

export default MainDashboard;
