import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useForm, Controller } from "react-hook-form";
import { Redirect, useHistory } from "react-router-dom";
import Auth from "../components/Auth";
import "./Login.css";

type FormValues = {
  username: string;
  password: string;
};

const Login: React.FC<any> = () => {
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const UserLoginCallback = () => {
    if (Auth.PK_user > 0) {
      history.replace({
        pathname: `/mdashboard`,
      });
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    }
  };

  const UserLoginFunction = (data: any) => {
    Auth.login(UserLoginCallback, data.username, data.password);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="tabcolor">
          <IonTitle size="large">Customer Service Database </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <LoginContainer /> */}

        <IonGrid className="maingrid ">
          <IonRow>
            <IonCol>
              <img
                src="assets/icon/logoicon.png"
                alt="ion"
                className="imgcontainer"
              ></img>
            </IonCol>
          </IonRow>
          {/* <form onSubmit={handleSubmit((data) => console.log(data))}> */}
          <form onSubmit={handleSubmit((data) => UserLoginFunction(data))}>
            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Username</IonLabel>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <IonInput
                        onIonChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        spellCheck={false}
                        autocapitalize="off"
                        clearInput
                      ></IonInput>
                    )}
                    control={control}
                    name="username"
                    rules={{
                      required: "This is required field",
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="username"
                    as={<div style={{ color: "red" }} />}
                  />
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonItem color="light" className="itemlistyle">
                  <IonLabel position="floating">Password</IonLabel>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <IonInput
                        onIonChange={onChange}
                        onBlur={onBlur}
                        name={name}
                        type="password"
                        spellCheck={false}
                        autocapitalize="off"
                        clearInput
                      ></IonInput>
                    )}
                    control={control}
                    name="password"
                    rules={{
                      required: "This is required field",
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    as={<div style={{ color: "red" }} />}
                  />
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol className="ion-text-center">
                <IonButton shape="round" type="submit" color="success">
                  Login
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
