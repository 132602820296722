class Auth {
  constructor() {
    this.globalwebhost = "https://api.spcmc.online";
    // this.globalwebhost = "http://localhost";
    this.username = "";
    this.PK_user = 0;
    this.PK_transaction = 0;
    this.data = "";
    this.statusnow = "";
    //this.ApiKey = "eyJhbGciOiJIUzI1NiJ9.eyJJc3N1ZXIiOiJTUEMgTUVESUNBTCIsIlVzZXJuYW1lIjoiQWRtaW4ifQ.-_YMiq7fmu9CUMwKoUTnh-Lf-YmBkE3mJcpYIBROzE4"
  }

  login(cb, username, password) {
    // fetch(this.globalwebhost + "/CSUapp/login.php", {
    fetch(this.globalwebhost + "/CSUapp/login.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //ApiKey: this.ApiKey,
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.PK_user === 0) {
          this.authenticated = false;
          alert("Login error: " + responseJson.message);
        } else {
          this.authenticated = true;
          this.PK_user = responseJson.PK_user;
          this.username = username;
          cb();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  logout(cb) {
    this.authenticated = false;
    this.PK_user = 0;
    cb();
  }

  navclient(cb, PK_transaction) {
    this.PK_transaction = PK_transaction;
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }

  typestatus(key) {
    if (key === "0") {
      this.statusnow = "Pending";
    } else if (key === "1") {
      this.statusnow = "Closed";
    }

    return this.statusnow;
  }
}

const authxxx = new Auth();

export default authxxx;
