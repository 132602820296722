import {
  add,
  lightFormat,
  parseISO,
  differenceInCalendarYears,
} from "date-fns";

class ALib {

    copyData(inputData: any, template: any, setData: React.Dispatch<any>): void {
        setData(this.recursiveCopy(inputData, template));
    }

    appendData(inputData: any, template: any, existingData: any, setData: React.Dispatch<any>): void {
        setData(existingData.concat(this.recursiveCopy(inputData, template)));
    }

    private recursiveCopy(inputData: any, template: any): any{
        if (Object.prototype.toString.call(inputData) === '[object Array]'){
            var tempRow: any;
            var tempArray: any[];

            tempArray = [];

            inputData.forEach((item: any) => {
                tempRow = {};
                template.map((e: any) => {
                    tempRow = this.recursiveCopy(item, e);
                });
                tempArray.push(tempRow);
            });

            return tempArray;
        }
        else {
            return this.recursiveCopyItem(inputData, template);
        }
    }

    private recursiveCopyItem(inputData: any, template: any): any {
        var tempRow: any;

        tempRow = {};
        Object.keys(template).forEach((key) => {
            if (Object.keys(inputData).includes(key)){
                var tempValue = Object.values(inputData)[Object.keys(inputData).indexOf(key)];
                if (Object.prototype.toString.call(tempValue) === '[object Array]'){
                    tempValue = this.recursiveCopy(tempValue, [key]);
                    tempRow = {...tempRow, [key]: tempValue};
                }
                else {
                    tempRow = {...tempRow, [key]: tempValue};
                }
            }
            else{
                tempRow = {...tempRow, [key]: Object.values(template)[Object.keys(template).indexOf(key)]};
            }
        });

        return tempRow;
    }

    removeEntry(inputData: any[], keyName: string, keyValue: any, setData: React.Dispatch<any[]>):boolean {
        var tempArray: any[];
        var iscopy: boolean;
        var deleted: boolean = false;

        tempArray = [];
        inputData.forEach((item) => {
            iscopy = true;
            Object.keys(item).forEach((key) => {
                if (key === keyName){
                    if(Object.values(item)[Object.keys(item).indexOf(key)] === keyValue){
                        iscopy = false;
                        deleted = true;
                    }
                }
            });
            if (iscopy) tempArray.push(item);
        });
        setData(tempArray);
        return deleted;
    }


  formatDBDate(value: Date): string {
    //console.log(value.toString()+" dd:"+value)
    return lightFormat(parseISO(value.toString()), "yyyy-MM-dd");
  }

  formatDate(value: string): string {
    if (this.isNotEmpty(value))
      return lightFormat(parseISO(value), "yyyy-MM-dd");
    return "";
  }

  displayDateTime(value: string): string {
    if (value) return lightFormat(parseISO(value), "MM/dd/yyyy hh:mm aa");
    else return "";
  }

  isNotEmpty(input: any): boolean {
    return (input?.trim()?.length || 0) > 0;
  }

  today(): string {
    return lightFormat(new Date(), "yyyy-MM-dd");
  }
  todaydatetime(): string {
    return lightFormat(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");
  }
  
  
  todaydatetime2(value: string): string {
    if (value) return lightFormat(parseISO(value), "yyyy-MM-dd'T'HH:mm:ss");
    else return "";
  }
}

const alib = new ALib();

export default alib;
